<template>
  <x-card
    class="export-list"
    size="xl"
  >
    <div class="head-block">
      <h2 class="headline-5 mb-3">
        Списки на выгрузку
      </h2>

      <x-btn
        type="button"
        prepend="plus"
        block
        @click="onDownloadClick"
      >
        Создать
      </x-btn>
    </div>

    <div class="filter-row">
      <x-form-item
        name="Выберите магазин"
        label="Выберите магазин"
      >
        <x-select
          v-model="selectShop"
          shadow
          track-by="id"
          label="label"
          :options="shopsList"
          :searchable="true"
          :allow-empty="false"
          placeholder="Магазин"
          @select="changeShop"
        />
      </x-form-item>
      <x-form-item
        name="Выберите период"
        label="Выберите период"
      >
        <datepicker
          has-reset-button
          :max-date="new Date()"
          :default-period="filtredPeriod"
          :value="period"
          @change="changeDate"
        />
      </x-form-item>
      <x-form-item
        name="Выберите отчёт"
        label="Выберите отчёт"
      >
        <x-select
          v-model="selectReport"
          shadow
          track-by="id"
          label="label"
          :options="reportOptions"
          :searchable="false"
          :allow-empty="false"
          placeholder="Отчёт"
          @select="changeReport"
        />
      </x-form-item>
    </div>

    <x-alert
      v-if="!isManager"
      class="mb-3"
    >
      В этом разделе будут храниться все выгрузки из системы, которые вы можете сформировать в {{ companyDetails.platformName }}
    </x-alert>

    <ExportAdvices v-if="!isManager" />

    <div
      v-if="isLoading"
      class="export-list__loader"
    >
      <loader />
    </div>

    <template v-else>
      <grid
        auto
        :cells="list"
        :columns="columns"
        class="export-list__grid"
      >
        <template #created="{ created }">
          {{ formatDate(created) }}
        </template>

        <template #period="{ period }">
          {{ period }}
        </template>

        <template #shopName="{ row }">
          <svg-icon
            v-if="row.marketplace_code"
            :name="row.marketplace_code"
            class="mp-icon"
          />
          <span class="marketplace__shop">
            {{ row.shop_name }}
          </span>
        </template>

        <template #status="{ row }">
          <status
            :status="row.status"
            :icon="statusIcons[row.status]"
            :status-list="statusList"
            :show-btn="isRecommendsBtnShown(row)"
            class="export-list__badge"
            @show-recommendation="onShowRecommendationModal(row)"
          />
        </template>
        <template #downloadUrl="{ row }">
          <x-btn
            v-if="isDownloadBtnShown(row)"
            icon="download"
            color="gray"
            @click="onDownloadList(row)"
          />
        </template>
        <template #reload="{ row }">
          <x-btn
            v-if="isManager"
            color="gray"
            @click="onRestartReport(row)"
          >
            <svg-icon
              name="reload"
              class="x-icon--s"
            />
          </x-btn>
        </template>
        <template #stop="{ row }">
          <x-btn
            v-if="isManager"
            icon="pause"
            @click="onStopReport(row)"
          />
        </template>
      </grid>

      <div
        v-if="exportsList.length"
        class="export-list__pagination"
      >
        <pagination
          :data="pagination"
          :current-page="currentPage"
          @change="onPaginationChange"
        />
      </div>

      <x-placeholder
        v-else
        icon="table-empty"
        desc="Списки на выгрузку отсутствуют"
      />

      <create-report-modal
        @close-modal="closeModal"
        @show-error-modal="showErrorModal"
      />
      <report-request-modal />
      <report-error-modal
        :error-message="errorMessage"
      />
      <report-recommendation-modal
        :recommendations="recommendations"
      />
    </template>
  </x-card>
</template>

<script>
import ExportAdvices from './ExportAdvices'
import Grid from '@/components/Interface/Grid.vue'
import Pagination from '@/components/Interface/Pagination.vue'
import Loader from '@/components/Common/Loader.vue'
import columns from '@/constants/exports'
import formatDate from '@/utils/date-formatter'
import CalendarPeriods from '@/constants/calendar-periods'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'
import PaginationMixin from '@/mixins/pagination'
import { createNamespacedHelpers } from 'vuex'
import companyDetails from '@/plugins/companyDetails'
import CreateReportModal from '@/components/Modals/CreateReportModal.vue'
import ReportErrorModal from '@/components/Modals/ReportErrorModal.vue'
import ReportRecommendationModal from '@/components/Modals/ReportRecommendationModal.vue'
import ReportRequestModal from '@/components/Modals/ReportRequestModal.vue'
import Datepicker from '@/components/Interface/Datepicker.vue'
import toastNotification from '@/utils/toast-notification'
import Status from '@/components/Exports/Status.vue'

const { mapActions, mapState } = createNamespacedHelpers('exports')
const { mapGetters, mapActions: mapMarketplaceActions, mapState: mapMarketplaceState } = createNamespacedHelpers('marketplaces')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')
const { mapActions: mapReportActions } = createNamespacedHelpers('reports')
const { mapState: mapProfileState } = createNamespacedHelpers('profile')

export default {
  mixins: [PaginationMixin],
  components: {
    ExportAdvices,
    Grid,
    Pagination,
    Loader,
    CreateReportModal,
    ReportErrorModal,
    ReportRequestModal,
    ReportRecommendationModal,
    Datepicker,
    Status
  },

  data () {
    return {
      columns,
      statusIcons: {
        finished: 'success-fill',
        failed: 'critical-error',
        failed_critical: 'critical-error',
        running: 'loader--gray',
        scheduled: 'clock'
      },
      statusList: {
        scheduled: 'В очереди',
        finished: 'Завершен',
        stopped: 'Остановлен',
        failed: 'Ошибка',
        running: 'В работе',
        failed_critical: 'Критическая ошибка'
      },
      isLoading: false,
      fetchIntervalId: null,
      errorMessage: '',
      recommendations: [],
      filtredPeriod: CalendarPeriods.CURRENT_WEEK,
      shopOptions: [],
      selectShop: null,
      selectReport: null,
      reportTypes: [],
      period: {
        start: '',
        end: ''
      },
      reportOptions: [
        {
          id: null,
          label: 'Все'
        },
        {
          id: 'daily_report',
          label: 'Ежедневный отчет'
        },
        {
          id: 'weekly_report',
          label: 'Еженедельный отчет'
        },
        {
          id: 'supply_forecast',
          label: 'Прогноз поставок'
        },
        {
          id: 'monthly_report',
          label: 'Месячный отчет'
        },
        {
          id: 'unit_economic_report',
          label: 'Unit-экономика'
        }
      ],
      exportsList: [],
      companyDetails
    }
  },

  computed: {
    // ...mapState(['exportsList']),
    ...mapProfileState(['user']),
    ...mapMarketplaceState(['shops']),
    ...mapMarketplaceActions(['getReportTypes']),

    shopsList () {
      const result = this.shops.map(shop => ({
        ...shop,
        label: shop.shop.name + ' (' + shop.id + ')',
        $icon: getMarketplaceIcon(shop.marketplace_code)
      }))
      result.unshift({
        id: null,
        label: 'Все'
      })
      return result
    },

    isManager () {
      return this.user.service_model && this.user.service_model === 'xway-service'
    },

    list () {
      return this.exportsList.map(el => ({
        ...el,
        period: formatDate(el?.meta?.date_from) + ' - ' + formatDate(el?.meta?.date_to)
      }))
    }
  },

  created () {
    this.fetchData()
    this.setExportsFetchInterval()
  },

  beforeDestroy () {
    clearInterval(this.fetchIntervalId)
  },

  methods: {
    ...mapActions(['getExports', 'getExportFile']),
    ...mapTrackingActions(['setEvent']),
    ...mapReportActions(['restartReport', 'stopReport']),

    formatDate (date) {
      return formatDate(date, 'DD MMMM YYYY г. HH:mm')
    },

    async fetchData (params, background = false) {
      try {
        if (!background) {
          this.isLoading = true
        }
        const payload = {
          additional_export_filters: {
            shop_id: this.selectShop?.id,
            date_from: this.period.start,
            date_to: this.period.end
          },
          mp_report_task_type: this.selectReport?.id,
          page: this.currentPage,
          limit: this.pagination.limit
        }
        const filters = {}

        for (const key in payload.additional_export_filters) {
          if (payload.additional_export_filters[key]) {
            filters[key] = payload.additional_export_filters[key]
          }
        }

        payload.additional_export_filters = filters

        const { data, pagination } = await this.getExports(payload)
        if (data) {
          this.pagination = { ...this.pagination, ...pagination }
          this.exportsList = data
          this.isLoading = false
        }
      } catch (e) {
        toastNotification.error('Ошибка загрузки данных')
      }
    },

    setExportsFetchInterval () {
      this.fetchIntervalId = setInterval(() => {
        this.fetchData({}, true)
      }, 10000)
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page

      this.pagination.limit = limit

      this.fetchData({ page, limit, offset })
    },

    isDownloadBtnShown (row) {
      return row.status === 'finished' &&
        row.download_file?.url &&
        row.download_file?.name
    },

    isRecommendsBtnShown (row) {
      return (row.status === 'failed' || row.status === 'failed_critical') &&
        !!row.recommendations && !!row.recommendations?.length
    },

    onDownloadList (row) {
      const { name, url } = row.download_file

      this.getExportFile({ name, url })

      this.setEvent({
        name: 'mmp_exportslist_report',
        product: 'MMP',
        screen: 'mmp_exportslist',
        marketplace_code: row.marketplace_code,
        report_id: row.id
      })
    },
    onRestartReport (row) {
      this.restartReport(row.id)
    },

    onStopReport (row) {
      this.stopReport(row.id)
    },

    async onDownloadClick () {
      this.$modal.show('create-report-modal')
    },

    closeModal () {
      this.$modal.show('report-request-modal')
    },

    showErrorModal (message) {
      this.errorMessage = message
      this.$modal.show('report-error-modal')
    },

    onShowRecommendationModal (row) {
      const data = row?.recommendations
      const recommends = []
      if (data) {
        data.forEach(entry => {
          const recommend = entry?.recommendation
          if (recommend) {
            recommends.push(recommend)
          }
        })
        this.recommendations = recommends
        this.$modal.show('report-recommendation-modal')
      }
    },

    changeShop (value) {
      this.selectShop = value
      this.currentPage = 1
      this.selectReport = null
      this.fetchData()
    },

    changeDate (value) {
      this.period = value
      this.currentPage = 1
      this.fetchData()
    },

    changeReport (value) {
      this.selectReport = value
      this.currentPage = 1
      this.fetchData()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .export-list

    &__loader
      text-align center

    &__grid
      margin 20px 0

      .grid__cell
        svg.mp-icon
          display inline-block
          vertical-align middle
          width 25px
          height 25px
          margin-right 5px

        span
          display inline-block
          vertical-align middle

    &__marketplace
      >>> .marketplace__desc
        display none

    &__badge
      >>> .svg-icon--loader--mini
        width 18px
        height 18px
        color #3cc13b

    .head-block
        display: flex
        margin-bottom: 20px

        .x-btn
          max-width: 200px
          margin-left: auto

    .filter-row
        .x-form-item
          width: 30%
          margin-right: 30px

  .badge
    display inline-flex
    align-items center
    padding 1px 6px
    border-radius 20px

    &__icon
      width 13px
      height 13px
      margin-right 5px

      &.svg-icon--pause
        fill #f9dd8d

  >>>.v-popover
    .trigger
      display flex !important
      align-items center
</style>
